import { Dropdown, DropdownButton } from "react-bootstrap";
import { UserRestrictedComponent } from "src/components/authorization/UserRestrictedComponent";
import { ComponentID } from "src/components/authorization/ComponentRightsMapping";
import ExternalResourceService from "src/service/ExternalResourceService";
import { AxiomMetricsDriver } from "src/metrics/AxiomMetricsDriver";
import { KatIcon } from "@amzn/katal-react";
import React from "react";

interface RadarCardDropdownProps {
    theme?: string;
    userId: string;
    viewUserLogin: string;
}

export function RadarCardDropdown(props: RadarCardDropdownProps) {
    const { userId, theme, viewUserLogin } = props;

    const renderPhoneToolViewItem = () => {
        return (
            <Dropdown.Item href={ExternalResourceService.phoneToolLink(props.userId)}
                onClick={() =>
                    AxiomMetricsDriver.publishLinkClick('RadarCard', 'phonetool',
                        ExternalResourceService.phoneToolLink(props.userId))}
                target="_blank">
                <KatIcon name="local_phone" size="small" />
                &nbsp;PhoneTool
            </Dropdown.Item>
        );
    }

    const renderBSCViewItem = () => {
        if (userId !== viewUserLogin) {
            return (
                <Dropdown.Item href={`/#/bsc/${userId}`}>
                    <KatIcon name="explore" size="small" />
                    &nbsp;BSC View
                </Dropdown.Item>
            );
        }
    }

    return (
        // @ts-expect-error-next-line
        <DropdownButton id="dropdown-basic-button"
            className={`axiom-dropdown-navigation ${theme}`}
            title={userId}>
            <UserRestrictedComponent componentId={ComponentID.BSC_DROPDOWN_PHONETOOL_RIGHTS_ID}>
                {renderPhoneToolViewItem()}
            </UserRestrictedComponent>
            <UserRestrictedComponent componentId={ComponentID.BSC_DROPDOWN_VIEW_RIGHTS_ID}>
                {renderBSCViewItem()}
            </UserRestrictedComponent>
        </DropdownButton>
    );

}